import {Route, Switch} from "react-router";
import GlobalSearchAdmin from "./globalSearch";
import {FullData} from "./FullData";
import UploadComp from "./Upload";
import Admin from "./Admin";
import Account from "./Account";

import App from "./App";
import React from "react";
import {FullPage} from "./pages/additional-data/FullPage";
import {RouteWithLayout} from "./layouts/RouteWithLayout";
import {MenuLayout} from "./layouts/MenuLayout";
import {ColumnsPreferences} from "./pages/settings/ColumnsPreferences";
import ProposedBOMs from "./pages/additional-data/ProposedBOM";
import {Reporting} from "./pages/settings/Reporting";

export function IHRouter() {
    return <div><Switch>
        <Route path='/global-search'
               render={props => <GlobalSearchAdmin {...props}/>}
        />
        <Route path='/proposed*'
               render={props => <ProposedBOMs {...props}/>}
        />
        <Route path='/additional-info*'
               render={props => <FullData {...props}/>}
        />


        <Route path='/data*'
               render={props => <FullPage {...props}/>}
        />

        <Route path='/upload'
               render={props => <UploadComp {...props}/>}
        />

        <Route path='/user'
               render={props => <Account {...props}/>}
        />

        <RouteWithLayout layout={MenuLayout} path="/settings/columns-preferences" component={ColumnsPreferences} />
        <RouteWithLayout layout={MenuLayout} path="/settings/reporting" component={Reporting} />
        <RouteWithLayout layout={MenuLayout} path="/settings/data-upload" component={Admin} />
        <RouteWithLayout layout={MenuLayout} path="/settings" />

        <Route path='/*'
               render={props => <App {...props}/>}
        />
    </Switch></div>
}
