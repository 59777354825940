import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import {Table} from 'react-bootstrap';
import './globalSearch.css';
import Menu from "./Menu";

import api from "./api";
import {Checkbox} from "antd";

class TableRow extends React.Component {

    render() {
        return (
            <tr>
                <td className='text-center'>{this.props.ind}</td>
                <td>{this.props.item}</td>
                <td onClick={() => this.props.onCheckBoxClick()}><Checkbox checked={this.props.checked}/></td>
            </tr>
        );
    }

}

class GlobalSearchAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            operators: [],
            info: {
                excludedCompanies: []
            },
            hub: {}
        }
    }


    getHubInfo() {
        api.get(`/hub-info`)
            .then(json => {
                if (!('excludedCompanies' in json.data)) {
                    json.data['excludedCompanies'] = []
                }
                this.setState({info: json.data, hub: json.data})
            })
            .catch(error => console.log(error))
    }

    getOperators() {
        api.get(`/operators?show_all=false`)
            .then(json => this.setState({operators: json.data}))
            .catch(error => console.log(error.response.data));
    }

    componentDidMount() {
        this.getHubInfo();
        this.getOperators();
    }

    onCheckBoxClick(operator) {
        let info = Object.assign({}, this.state.info);
        if (info.excludedCompanies.indexOf(operator) > -1) {
            info.excludedCompanies = info.excludedCompanies.filter(op => op !== operator)
        } else {
            info.excludedCompanies.push(operator)
        }
        this.setState({info: info})
        this.saveChanges(info);
    }

    saveChanges(info) {
        api.post(`/save-changes`, info)
            .then(() => {
                this.getHubInfo();
                alert('Changes were saved!')
            })
            .catch(error => console.log(error.response.data));
    }

    render() {

        const info = this.state.info;

        let rows = [];
        if (this.state.hub) {
            rows = this.state.operators.map((v, i) =>
                <TableRow key={v}
                          ind={i + 1}
                          item={v}
                          onCheckBoxClick={() => this.onCheckBoxClick(v)}
                          checked={info.excludedCompanies.indexOf(v) === -1}
                />);
        }

        return (
            <div>

                <Menu history={this.props.history}/>
                <div className="Global-admin-root center-block">
                    <div className='text-center center-block hub-name'>
                        {this.state.info.full_name}
                    </div>
                    <div className="Global-admin-table center-block">
                        <Table striped bordered condensed hover className="Items">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Operator</th>
                                <th>Available for global search</th>
                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </div>

                </div>
            </div>
        );
    }

}

export default GlobalSearchAdmin;
