import React from "react";

import {  Tree, Input  } from 'antd'
import {Panel} from "react-bootstrap";
import Menu from "../../Menu";
import api from "../../api";


class ProposedBOMs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            material: '',
            data: []
        }
    }

    componentDidMount() {
        let material = new URLSearchParams(window.location.search).get('q');
        api.get(`/proposed?material=${material}`)
            .then((json) => {
                this.setState({data: json.data})
            })
            .catch(error => {
                    if(error.response.data.msg){
                        alert(error.response.data.msg)
                    } else {
                        alert(error.response.data)
                    }
                }
            )
            .finally(() => this.setState({
                isLoading: false
            }));
        this.setState({
            material: material
        })
    }

    handleToggle = (event, nodeIds) => {
        this.setState({expanded: nodeIds});
    };

    handleSelect = (event, nodeIds) => {
        this.setState({selected: nodeIds});
    };

    render() {
        return (
            <div>
                <Menu history={this.props.history}/>
                <div className="catalog-root center-block">
                    <h3 className='text-center'>Proposed BOM for Equipment {this.state.material}</h3>
                    <Panel bsStyle="primary">
                        <Panel.Body>
                            <SearchTree data={this.state.data} />
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }

}

class SearchTree extends React.Component {
    state = {
        expandedKeys: [],
        searchValue: '',
        autoExpandParent: true,
        dataList:[]
    };


    generateList = data => {
        let dataList = []
        for (let node of data) {
            const { key } = node;
            dataList.push({ key, title: key });
            if (node.children) {
                dataList = dataList.concat(this.generateList(node.children));
            }
        }
        return dataList;
    };

    openTree = (data) => {
        let expanded = []
        for (let node of data) {
            const { key } = node;
            expanded.push(key);
            if (node.children) {
                expanded = expanded.concat(this.openTree(node.children));
            }
        }
        return expanded
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.setState({dataList: this.generateList(this.props.data), expandedKeys: this.openTree(this.props.data)})
        }
    }


    getParentKey = (key, tree) => {
        let parentKey;
        for (let node of tree) {
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onChange = e => {
        const { value } = e.target;
        const expandedKeys = this.state.dataList
            .map(item => {
                if (item.title.toUpperCase().indexOf(value.toUpperCase()) > -1) {
                    return this.getParentKey(item.key, this.props.data);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };

    render() {
        const { searchValue, expandedKeys, autoExpandParent } = this.state;
        const loop = data =>
            data.map(item => {
                const index = item.title.toUpperCase().indexOf(searchValue.toUpperCase());
                const beforeStr = item.title.substr(0, index);
                const value = item.title.substr(index, searchValue.length);
                const afterStr = item.title.substr(index + searchValue.length);
                let title =
                    index > -1 ? (
                        <span>
              {beforeStr}
                            <span className="site-tree-search-value">{value}</span>
                            {afterStr}
            </span>
                    ) : (
                        <span>{item.title}</span>
                    );
                if (item.children && item.children.length > 0) {
                    title = <b style={{fontSize: 16}}>{title}</b>
                    return { title, key: item.key, children: loop(item.children) };
                }

                return {
                    title,
                    key: item.key,
                };
            });
        return (
            <div>
                <div style={{ marginLeft: 600}}>
                    <Input style={{ marginBottom: 8, width: 550}} placeholder="Search" onChange={this.onChange} />
                </div>
                <Tree
                    onExpand={this.onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    treeData={loop(this.props.data)}
                />
            </div>
        );
    }
}


export default ProposedBOMs;
