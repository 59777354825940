import 'react-app-polyfill/ie9';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './index.less'
import {Provider} from 'react-redux'
import {configureStore, history} from "./configureStore";
import * as serviceWorker from './serviceWorker';
import {IHRouter,} from "./Router";
import {CurrentUserProvider} from "./contexts/CurrentUserContext";
import {Auth, UIConfigContext} from "@digatex/digatex-ui-lib"
import axios from "axios";
import {UI_CONFIG} from "./properties"
import {ConnectedRouter} from "connected-react-router";
import {withRouter} from "react-router";


const store = configureStore()

axios.interceptors.request.use((config) => {
    if (Auth.isAuthenticated()) {
        const cb = () => {
            config.headers = {...config.headers, ...Auth.headers(config.url)}
            return Promise.resolve(config);
        };
        return Auth.updateToken(cb);
    }
});

axios.interceptors.response.use(
    response => response,
    error => {
        const status = error.response.status;
        if (status && (status >= 400 && status <= 599)) {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

const Router = withRouter(IHRouter)

Auth.init(() => ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <UIConfigContext.Provider value={UI_CONFIG}>
                <CurrentUserProvider>
                    <Router/>
                </CurrentUserProvider>
            </UIConfigContext.Provider>
        </ConnectedRouter>
    </Provider>,

    document.getElementById('root')))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
