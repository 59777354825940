import React from 'react'
import {Table} from "antd";


export function AntdTable ({total, columns, data, loading, scroll, currentPage, tableSize, pageSize, onChange, tableProps, rowSelection}) {

    return <Table columns={columns} dataSource={data} {...tableProps}
                  size={tableSize}
                  loading={loading}
                  onChange={onChange}
                  rowSelection={rowSelection}
                  pagination={
                      {
                          total: total,
                          current: currentPage,
                          pageSize: pageSize ? pageSize : 50
                      }
                  }
                  scroll={scroll ? {y: scroll} : {y: 300}}/>
}
