import React from "react";
import {AntdTable} from "../../components/custom/AntdTable";
import {createIHLink} from "./BomDataTab";
import {DEFAULT_OPERATOR} from "../../properties";
import {getFilterValue} from "../../helpers";


export function WhereUsedTable ({equipment}) {

    const columns = [
        {
            key: 'label',
            title: '',
            dataIndex: 'label',
        },{
            key: 'material',
            title: 'Equipment',
            dataIndex: 'equipment',
            render: (floc, item) => createIHLink(item.operator || DEFAULT_OPERATOR, floc)
        },
        {
            key: 'floc',
            title: 'Functional Location',
            dataIndex: 'floc',
            onFilter: (value, record) => record['floc'] === value,
            filters: getFilterValue(equipment, 'floc')
        }, {
            key: 'bom',
            title: 'BOM',
            dataIndex: 'bom_number',
            onFilter: (value, record) => record['bom_number'] === value,
            filters: getFilterValue(equipment, 'bom_number')
        }, {
            key: 'description',
            title: 'Description',
            dataIndex: 'description',
        }, {
            key: 'tag',
            title: 'Tag #',
            dataIndex: 'tag',
        }, {
            key: 'area',
            title: 'Area',
            dataIndex: 'area',
            onFilter: (value, record) => record['area'] === value,
            filters: getFilterValue(equipment, 'area')
        }, {
            key: 'quantity',
            title: 'Installed Quantity',
            dataIndex: 'quantity',
        },
    ]

    const getFullRows = () => {
        if (equipment) {
            let rows = [...equipment]
            let installed = equipment.map(v => parseInt(v.quantity)).reduce((a, b) => a + b, 0)
            rows.push({
                label: <b>Total Installed</b>,
                'quantity': installed,
            })
            return rows
        } else {
            return []
        }
    }

    return <AntdTable tableSize={'small'} scroll={300} data={getFullRows()} columns={columns}  />

}
