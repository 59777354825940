import React, {useState} from "react";
import {Card, Col, Collapse, Row} from "antd";
import {properties} from "../../properties";
import * as Auth from "../../AuthService";
import axios from "axios";
import FileDownload from "js-file-download";
import {ControlLabel, Form, FormGroup} from "react-bootstrap";
import DatePicker from "react-16-bootstrap-date-picker";
import Button from "react-bootstrap-button-loader";

const {Panel} = Collapse;

export function Reporting() {

    const [submitInProgressUsage, setSubmitInProgressUsage] = useState(false);
    const [triedToSubmitUsage, setTriedToSubmitUsage] = useState(false)
    const [submitInProgressQueries, setSubmitInProgressQueries] = useState(false);
    const [triedToSubmitQueries, setTriedToSubmitQueries] = useState(false)
    const [submitInProgressStat, setSubmitInProgressStat] = useState(false);
    const [triedToSubmitStat, setTriedToSubmitStat] = useState(false)


    const [dateFromUsage, setDateFromUsage] = useState('')
    const [dateFromStrUsage, setDateFromStrUsage] = useState();
    const [dateToUsage, setDateToUsage] = useState();
    const [dateToStrUsage, setDateToStrUsage] = useState();

    const [dateFromQueries, setDateFromQueries] = useState('')
    const [dateFromStrQueries, setDateFromStrQueries] = useState();
    const [dateToQueries, setDateToQueries] = useState();
    const [dateToStrQueries, setDateToStrQueries] = useState();

    const getUsageReport = (e, endpoint)  => {
        e.preventDefault();
        endpoint === 'report' ? setTriedToSubmitUsage(true) : setTriedToSubmitQueries(true)
        const dateFrom = endpoint === 'report' ? dateFromUsage : dateFromQueries
        if (dateFrom != null) {
            endpoint === 'report' ? setSubmitInProgressUsage(true) : setSubmitInProgressQueries(true)
            const dateFromStr = endpoint === 'report' ? dateFromStrUsage : dateFromStrQueries
            let url = `${properties.apiUrl}/api/user/${endpoint}?from=${dateFromStr}`;
            const dateTo = endpoint === 'report' ? dateToUsage : dateToQueries
            if (dateTo != null) {
                const dateToStr = endpoint === 'report' ? dateToStrUsage : dateToStrQueries
                url += `&to=${dateToStr}`
            }

            const config = Auth.createConfig();
            config['responseType'] = 'blob';

            axios.get(url, config)
                .then(response => {
                    const header = response.headers['content-disposition'];
                    const filename = /filename=(.*)/.exec(header)[1];
                    FileDownload(response.data, filename, filename);
                })
                .catch(error => alert(error))
                .finally(() => {
                    endpoint === 'report' ? setTriedToSubmitUsage(false) : setTriedToSubmitQueries(false)
                    endpoint === 'report' ? setSubmitInProgressUsage(false) : setSubmitInProgressQueries(false)
                });
        }
    }

    const checkRequired = (value, triedToSubmit) =>  {
        return (value == null && triedToSubmit) ? 'error' : null;
    }

    const download = (end) => {
        let url = `${properties.apiUrl}/api/user/${end}`;
        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        axios.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
    }


    return <Row style={{padding: 10}}>
        <Col span={8}
             style={{padding: 5}}
        >
            <Card
                title="User Access Statistics"
                bordered={false}
            >
                <Form onSubmit={e => getUsageReport(e)}>
                    <FormGroup controlId="dateFrom" className="required col-md-6 Report-date-from"
                               validationState={checkRequired(dateFromUsage, triedToSubmitUsage)}>
                        <ControlLabel>Date From</ControlLabel>
                        <DatePicker value={dateFromUsage} dateFormat="YYYY-MM-DD"
                                    onChange={(value, formattedValue) => {
                                        setDateFromUsage(value)
                                        setDateFromStrUsage(formattedValue)
                                    }}
                        />
                    </FormGroup>
                    <FormGroup controlId="dateTo" className="col-md-6 Report-date-to">
                        <ControlLabel>Date To (inclusive)</ControlLabel>
                        <DatePicker value={dateToUsage} dateFormat="YYYY-MM-DD"
                                    onChange={(value, formattedValue) => {
                                        setDateToUsage(value)
                                        setDateToStrUsage(formattedValue)
                                    }}
                        />
                    </FormGroup>
                </Form>
                <Button style={{float: 'right'}} type="submit" onClick={e => getUsageReport(e, 'report')} bsStyle="primary"
                        loading={submitInProgressUsage}>Download</Button>
            </Card>
        </Col>
        <Col span={8}
             style={{padding: 5}}
        >
            <Card
                title="User Queries Report"
                bordered={false}
            >
                <Form onSubmit={e => getUsageReport(e)}>
                    <FormGroup controlId="dateFrom" className="required col-md-6 Report-date-from"
                               validationState={checkRequired(dateFromQueries, triedToSubmitQueries)}>
                        <ControlLabel>Date From</ControlLabel>
                        <DatePicker value={dateFromQueries} dateFormat="YYYY-MM-DD"
                                    onChange={(value, formattedValue) => {
                                        setDateFromQueries(value)
                                        setDateFromStrQueries(formattedValue)
                                    }}
                        />
                    </FormGroup>
                    <FormGroup controlId="dateTo" className="col-md-6 Report-date-to">
                        <ControlLabel>Date To (inclusive)</ControlLabel>
                        <DatePicker value={dateToQueries} dateFormat="YYYY-MM-DD"
                                    onChange={(value, formattedValue) => {
                                        setDateToQueries(value)
                                        setDateToStrQueries(formattedValue)
                                    }}
                        />
                    </FormGroup>
                </Form>
                <Button style={{float: 'right'}} type="submit" onClick={e => getUsageReport(e, 'queries')} bsStyle="primary"
                        loading={submitInProgressQueries}>Download</Button>
            </Card>
        </Col>
        <Col span={8}
             style={{padding: 5}}
        >
            <Card
                title="User Statistics"
                bordered={false}
            >
                <Button style={{float: 'right'}} type="submit" onClick={e => download('stats')} bsStyle="primary"
                        loading={submitInProgressStat}>Download</Button>
            </Card>
        </Col>
    </Row>

}
