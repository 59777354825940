import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import {Table, Image} from 'react-bootstrap';
import './Upload.css';
import * as _ from 'lodash'
import Button from "react-bootstrap-button-loader";
import Menu from "./Menu";
import api from "./api";
import Auth from "./Auth";
const FileDownload = require('js-file-download');

class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: [],
            current: ''
        }
    }

    componentDidMount() {
        api.get(`/dirs`)
            .then(json => this.setState({navigation: json.data}))
            .catch(error => console.log(error))
    }

    selectDir(dir, path){
        this.setState({current: dir});
        this.props.showFiles(path + '/' + dir)
    }

    render() {

        let nav = this.state.navigation.map((v, i) =>
            <div key={i}>
                {_.capitalize(v.name)}
                {
                    v.types.map((t, j) =>
                        <div key={j}
                             onClick={() => this.selectDir(t, v.name)}>
                            <li style={{color: t=== this.state.current ? 'red' : ''}}>
                                {t}
                            </li>
                        </div>)
                }
            </div>
        );

        return (
            <div>
                <div className='library' style={{marginTop: this.state.height ? this.state.height + 5 : 0}}>
                    {nav}
                </div>
            </div>
        );
    }

}


class Commands extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: ''
        }
    }

    render() {
        return (
            <div>
                <span>
                    <input type="file" onChange={e => this.props.uploadFile(e)} multiple
                           disabled={this.state.progress !== ''} aria-label='Select one or multiple files to upload'/>
                    <span>{this.state.progress}</span>
                </span>
                <span style={{float: 'right', color: 'blue', marginTop: -25}}>
                    <span onClick={() => this.props.moveToArchive()}>Move inactive guides to archive</span>
                </span>
            </div>
        );
    }

}


class FileRow extends React.Component {

    render() {
        return (
            <tr style={{backgroundColor: this.props.file.active ? '#ebffdb' : ''}}>
                <td>{this.props.index}</td>
                <td>{this.props.file.name}</td>
                <td>{this.props.file.date}</td>
                <td>
                    <Button id="logout" bsStyle="link" onClick={(e) => this.props.download(e)} className='text-center' title="Download">
                        <Image src="/images/download.png"/>
                    </Button>
                </td>
                <td style={{color: 'blue'}} onClick={() => this.props.setActive()}>Set active</td>
            </tr>
        );
    }

}


class FileTable extends React.Component {

    render() {

        let arrow = this.props.asc ?
                <Image src='/images/arrow-up.png' onClick={() => this.props.sort(false)}/> :
            <Image src='/images/arrow-down.png' onClick={() => this.props.sort(true)}/>;

        let files = this.props.files.map((v, i) => <FileRow key={i}
                                                            download={(e) => this.props.download(e, v)}
                                                            setActive={() => this.props.setActive(v)}
                                                            index={i + 1} file={v}/>);

        return (
            <div>
                <Table striped bordered condensed hover className="Items">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Date of upload {arrow}</th>
                        <th>Download version</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {files}
                    </tbody>
                </Table>
            </div>
        );
    }

}


class UploadComp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            asc: false
        }
    }

    showFiles(dir, asc = this.state.asc) {
        api.get(`/files?dir=${dir}&asc=${asc}`)
            .then(json => this.setState({files: json.data, dir: dir, asc: asc}))
            .catch(error => console.log(error))
    }

    uploadFile(e) {
        const formData = new FormData();
        const input = e.target;
        if (input.files.length > 0) {
            for (let file of input.files) {
                formData.append(file.name, file);
            }
            const config = Auth.headers();
            config['Content-Type'] = 'multipart/form-data';
            config['onUploadProgress'] = progressEvent => {
                this.setState({progress: progressEvent.loaded < progressEvent.total ? 'Uploading files...' : 'Processing files...'});
            };
            api
                .post(`/upload/guide?dir=${this.state.dir}`, formData, config)
                .then(resp => {
                    alert('Your files were successfully uploaded!');
                    this.showFiles(this.state.dir)
                })
                .catch(error => alert(error.response.data))
                .finally(() => {
                    input.value = '';
                    this.setState({progress: ''});
                });
        }
    }

    setActive(file) {
        api.get(`/guides/active?_id=${file._id}&dir=${this.state.dir}`)
            .then(() => this.showFiles(this.state.dir))
            .catch(error => console.log(error))
    }

    moveToArchive() {
        api.get(`/guides/archive?dir=${this.state.dir}`)
            .then(() => this.showFiles(this.state.dir))
            .catch(error => console.log(error))
    }

    download(e, file) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        let url = `/download/guide?_id=${file['_id']}`;

        const config = Auth.headers();
        config['responseType'] = 'blob';

        api.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1].replace(/"/g,'');
                FileDownload(response.data, filename, filename);
            })
            .catch(error => alert(error))
            .finally(() => this.setState({
                triedToSubmit: false,
                submitInProgress: false
            }));
    }

    render() {
        return (
            <div>
                <Menu />
                <Navigation showFiles={(dir => this.showFiles(dir))}/>
                <div className='Account-root center-block'>
                    {this.state.dir && <Commands uploadFile={(e) => this.uploadFile(e)}
                                                 moveToArchive={() => this.moveToArchive()}/>}
                    {this.state.files.length > 0 && <FileTable
                        setActive={(filename) => this.setActive(filename)}
                        asc = {this.state.asc}
                        sort={(value) => this.showFiles(this.state.dir, value)}
                        download={(e, file) => this.download(e, file)}
                        files={this.state.files}/>}
                </div>

            </div>
        );
    }

}

export default UploadComp;
