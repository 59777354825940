import './Admin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import './index.css';

import React, { Component } from 'react';
import { Table, Panel } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Button from 'react-bootstrap-button-loader';


import { properties } from './properties.js';
import api from "./api";
import Auth from "./Auth";

require('promise.prototype.finally').shim();

class Versions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            versions: [],
            switchInProgress: false
        }
    }

    componentDidMount() {
        this.loadVersions()
    }

    componentDidUpdate(prevProps) {
        if (this.props.approvedVersion !== prevProps.approvedVersion) {
            this.loadVersions();
        }
    }

    loadVersions() {
        api.get(`/versions`)
            .then(json => this.setState({current: json.data.current, selected: json.data.current, versions: json.data.versions}))
            .catch(error => console.log(error));
    }

    onSelectVersion() {
        if (this.state.selected !== this.state.current) {
            this.setState({switchInProgress: true});
            api.post(`/version/${this.state.selected}/select`, {})
                .then(() => {
                    this.setState({switchInProgress: false});
                    this.loadVersions();
                })
                .catch(error => alert(error.response.data));
        }
    }

    render() {
        const options = this.state.versions.map((v, i) =>
            <option value={v.version} key={v.version}>{v.version} / {v.updated} {v.version === this.state.current ? ' (current)' : ''}</option>
        );
        return (
            <Panel bsStyle="primary">
                <Panel.Heading>Switch version</Panel.Heading>
                <Panel.Body className="Versions-panel">
                    <p>Switch to another Inventory version</p>
                    <select value={this.state.selected} onChange={e => this.setState({selected: e.target.value})}>
                        {options}
                    </select>
                    <Button bsStyle="primary" loading={this.state.switchInProgress} onClick={() => this.onSelectVersion()}>Select</Button>
                </Panel.Body>
            </Panel>
        );
    }
}

class Preview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            approveInProgress: false,
            rows: []
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.version_id !== prevProps.version_id) {
            this.handlePageChange(1);
        }
    }

    handlePageChange(page) {
        this.setState({page: page});
        const from = (page - 1) * properties.previewRowsPerPage;
        const to = Math.min(from + properties.previewRowsPerPage, this.props.size);
        api.get(`/version/${this.props.version_id}/preview?from=${from}&to=${to}`)
            .then(json => this.setState({rows: json.data}))
            .catch(error => alert(error.response.data));
    }

    onApproveVersion() {
        this.setState({approveInProgress: true});
        api.post(`/version/${this.props.version_id}/approve`, {})
            .then(() => {
                this.setState({rows: [], approveInProgress: false});
                this.props.onApprove(this.props.version_id);
            })
            .catch(error => alert(error.response.data));
    }

    render() {
        if (this.state.rows.length > 0) {

            const index_from = (this.state.page - 1) * properties.previewRowsPerPage;
            const rows = this.state.rows.map((item, i) =>
                <tr key={item.material}>
                    <td className="text-center">{index_from + i + 1}</td>
                    <td className="text-nowrap">{item.operator} {item.material}</td>
                    <td>{item.description}</td>
                    <td>{item.part_number}</td>
                </tr>
            );

            return (
                <Panel bsStyle="primary">
                    <Panel.Heading>Preview uploaded data</Panel.Heading>
                    <Panel.Body className="Preview-panel">
                        <p><strong>Total records:</strong> {this.props.size}</p>
                        <p><strong>Files processed:</strong> {this.props.files}</p>
                        <div>
                            <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={properties.previewRowsPerPage}
                                totalItemsCount={this.props.size}
                                pageRangeDisplayed={5}
                                onChange={page => this.handlePageChange(page)}
                            />
                            <Button bsStyle="primary" loading={this.state.approveInProgress}
                                    onClick={() => this.onApproveVersion()}>Approve new version</Button>
                        </div>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Material</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
            );
        } else {
            return null;
        }
    }
}

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: '',
            approvedVersion: null,
            isSuperAdmin: false
        }
    }

    componentDidMount() {
        api.get(`/validate_permission?permission=superadmin`)
            .then(() => {
                this.setState({isSuperAdmin: true})
            })
            .catch(error => {
                console.log(error.response.data);
                this.setState({isSuperAdmin: false})
            });
    }

  restart() {
      alert('Start restarting the Hub')
      api.get(`/restart`)
      .then(() => alert('Inventory Hub was restarted'))
      .catch(error => console.log(error))
  }

    uploadFile(e) {
        const formData = new FormData();
        const input = e.target;
        if (input.files.length > 0) {
            for (let file of input.files) {
                formData.append(file.name, file);
            }
            const config = Auth.headers();
            config['Content-Type'] = 'multipart/form-data';
            config['onUploadProgress'] = progressEvent => {
                this.setState({progress: progressEvent.loaded < progressEvent.total ? 'Uploading files...' : 'Processing files...'});
            };
            api
                .post(`/upload`, formData, config)
                .then(resp => {
                    alert('Your files were successfully uploaded!');
                    this.setState({
                    version_id: resp.data.version_id,
                    size: resp.data.size,
                    files: resp.data.files
                })})
                .catch(error => alert(error.response.data))
                .finally(() => {
                    input.value = '';
                    this.setState({progress: ''});
                });
        }
    }

    render() {
        return (
            <div>
            <div className="Admin-root center-block">
                {/*<Versions approvedVersion={this.state.approvedVersion}/>*/}
                <Panel bsStyle="primary">
                    <Panel.Heading>Upload new data</Panel.Heading>
                    <Panel.Body>
                        Select one or multiple files to upload
                        <input type="file" onChange={e => this.uploadFile(e)} multiple disabled={this.state.progress !== ''}/>
                        <span>{this.state.progress}</span>
                    </Panel.Body>
                </Panel>
                {this.state.isSuperAdmin && <Panel bsStyle="primary">
                    <Panel.Body>
                        <a href='/upload'>Go to Quick Reference guide upload</a>
                    </Panel.Body>
                </Panel>}
              {this.state.isSuperAdmin && <Panel bsStyle="primary">
                <Panel.Body>
                  <a href='/global-search'>Global search admin page</a>
                </Panel.Body>
              </Panel>}
              {this.state.isSuperAdmin && <Panel bsStyle="primary">
                <Panel.Body>
                  <a onClick={() => this.restart()}>Restart the Hub</a>
                </Panel.Body>
              </Panel>}
                <Preview version_id={this.state.version_id} size={this.state.size} files={this.state.files}
                         onApprove={version => this.setState({approvedVersion: version})}/>
            </div>
            </div>
        );
    }
}

export default Admin;
