import React from "react";
import {AntdTable} from "../../components/custom/AntdTable";
import {DEFAULT_OPERATOR} from "../../properties";
import {getFilterValue} from "../../helpers";


export const createIHLink = (operator, material) => {
    return <a target="_blank" rel="noopener noreferrer" href={`/data?operator=${operator}&material=${material}`}>{material}</a>
}

export function BomDataTab ({equipment}) {

    const columns = [
        {
            key: 'bom',
            title: 'BOM',
            dataIndex: 'bom',
            onFilter: (value, record) => record['bom'] === value,
            filters: getFilterValue(equipment, 'bom')
        }, {
            key: 'material',
            title: 'Material',
            dataIndex: 'material',
            render: (material, item) => createIHLink(item.operator || DEFAULT_OPERATOR, material)
        },
        {
            key: 'item',
            title: 'Item',
            dataIndex: 'item',
        }, {
            key: 'description',
            title: 'Description',
            dataIndex: 'description',
        }, {
            key: 'Installed',
            title: 'Installed',
            dataIndex: 'installed',
        }, {
            key: 'uom',
            title: 'UOM',
            dataIndex: 'unit',
        }]

    return <AntdTable tableSize={'small'} scroll={300} data={equipment} columns={columns}  />

}
