import {AntdTable} from "../../components/custom/AntdTable";
import React from "react";
import {getFilterValue} from "../../helpers";

export function MaintenanceWork({data}) {

    const columns = [{
        key: 'date',
        title: 'Created Date',
        dataIndex: 'created'
    },{
        key: 'order',
        title: 'Order',
        dataIndex: 'maint_item'
    },{
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
    },{
        title: 'ABC Indic.',
        dataIndex: 'abc',
        key: 'abc',
        onFilter: (value, record) => record['abc'] === value,
        filters: getFilterValue(data, 'abc')
    },{
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        onFilter: (value, record) => record['type'] === value,
        filters: getFilterValue(data, 'type')
    },{
        title: 'Type Description',
        dataIndex: 'type_description',
        key: 'type_description',
        onFilter: (value, record) => record['type_description'] === value,
        filters: getFilterValue(data, 'type_description')
    },{
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
        onFilter: (value, record) => record['plant'] === value,
        filters: getFilterValue(data, 'plant')
    },{
        title: 'Work Centre',
        dataIndex: 'work_centre',
        key: 'work_centre',
        onFilter: (value, record) => record['work_centre'] === value,
        filters: getFilterValue(data, 'work_centre')
    },{
        title: 'Floc',
        dataIndex: 'floc',
        key: 'floc'
    },{
        title: 'Sort Field',
        dataIndex: 'sort_field',
        key: 'sort_field',
        onFilter: (value, record) => record['sort_field'] === value,
        filters: getFilterValue(data, 'sort_field')
    },]


    return <AntdTable data={data} tableSize={'small'} columns={columns} />
}
