import React from "react";
import {AntdTable} from "../../components/custom/AntdTable";

export function UsageTab ({usageInfo}) {

    const getColumns = () => {
        const columns = [
            {
                key: 'year',
                title: <b>Year</b>,
                dataIndex: 'year',
                render: year => <b>{year}</b>
            }, {
                key: 'total',
                title: <b>Total</b>,
                dataIndex: 'total',
            },
        ]

        const monthsColumns = usageInfo.months.map(m => {
            return {
                key: m,
                title: <b>{m}</b>,
                dataIndex: m,
                render: val => val ? val : ''
            }
        })

        monthsColumns.push({
            key: 'demand',
            title: <b>Average Use</b>,
            dataIndex: 'total',
            render: (total, item) => Math.round((!item.av ? total : item.av) / 12 * 100) / 100
        })
        return [...columns, ...monthsColumns]
    }

    const getMonthTotal = () => {
        let result = {}
        for (let m of usageInfo.months) {
            result[m] = [...usageInfo.stats].map(v => parseInt(v[m] || 0)).reduce((a, b) => a + b, 0)
        }
        return result
    }
    const getRows = () => {
        const rows = [...usageInfo.stats]

        const monthTotal = getMonthTotal()

        let total = {
            year: 'Total Demand',
            total: rows.map(v => parseInt(v.total)).reduce((a, b) => a + b, 0) ,
            av: rows.map(v => parseInt(v.total)).reduce((a, b) => a + b, 0) / rows.length
        }
        total = {...total, ...monthTotal}

        rows.push(total)
        return rows
    }

    return <>
        <AntdTable tableSize={'small'} scroll={300} data={getRows()} columns={getColumns()}/>
    </>

}
